import styled from 'styled-components';
import { breakpoint } from "../../utils/breakpoints";
export var DropDownButtonStyled = styled.button.withConfig({
  displayName: "HeaderStaticBlockstyled__DropDownButtonStyled",
  componentId: "sc-1uduhid-0"
})(["display:flex;border:none;outline:none;color:white;margin:0;text-transform:uppercase;", ";svg{margin-left:8px;color:#F5D1CF;}"], {
  "textTransform": "uppercase",
  "justifyContent": "center",
  "alignItems": "baseline",
  "fontWeight": "500",
  "fontFamily": "HK Nova, sans-serif"
});
export var DropDownContentStyled = styled.div.withConfig({
  displayName: "HeaderStaticBlockstyled__DropDownContentStyled",
  componentId: "sc-1uduhid-1"
})(["", ";position:absolute;background-color:rgb(18 18 18 / 90%);min-width:160px;box-shadow:0 8px 16px 0 rgba(0,0,0,0.2);border-radius:10px;z-index:1;top:5rem;backdrop-filter:blur(5px);", "{top:5.5rem;margin-left:-1.875rem;}ul{display:flex;flex-direction:column;align-items:flex-start;justify-content:flex-start;padding:1.563rem 1.875rem;margin:0;li{text-align:left;display:flex;justify-content:flex-start !important;border:none;padding:.5rem 0 !important;font-size:.938rem;&:not(:last-child){margin-bottom:1.063rem;}a{color:#fff;padding:0;font-size:1rem;text-transform:none;&:hover{color:#EABFBD;opacity:1;}}}}"], props => props.isOpen ? {
  "display": "block"
} : {
  "display": "none"
}, breakpoint('lg'));
export var StyledHeaderStaticDesktop = styled.div.withConfig({
  displayName: "HeaderStaticBlockstyled__StyledHeaderStaticDesktop",
  componentId: "sc-1uduhid-2"
})(["", ";", ";.navbar{overflow:hidden;", ";li{", ";display:flex;justify-content:center;a{", ";color:#fff;transition:all .3s ease-in-out;&:hover{color:#F5D1CF;}}&:first-child{", ";}&:not(:last-child){a{line-height:1;}}}}.dropdown{&:focus-visible{outline:-webkit-focus-ring-color auto 1px;}&:hover{", "{color:#F5D1CF;transition:all .3s ease-in-out;svg{color:#FFF;}}}", "{&:focus-visible{outline:-webkit-focus-ring-color auto 1px;}}", "}"], {
  "display": "none"
}, {
  "@media (min-width: 1280px)": {
    "display": "flex"
  }
}, {
  "display": "flex",
  "listStyleType": "none",
  "alignItems": "center",
  "margin": "0px",
  "padding": "0px",
  "height": "4rem",
  "@media (min-width: 1024px)": {
    "height": "5rem"
  }
}, {
  "paddingTop": "0px",
  "paddingBottom": "0px",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "textTransform": "uppercase",
  "fontWeight": "500",
  "borderLeftWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(var(--color-gray-800) / var(--tw-border-opacity))",
  "fontFamily": "HK Nova, sans-serif",
  "@media (min-width: 1280px)": {
    "paddingLeft": "1.6rem",
    "paddingRight": "1.6rem"
  },
  "@media (min-width: 1536px)": {
    "paddingLeft": "1.75rem",
    "paddingRight": "1.75rem"
  }
}, {
  "textDecoration": "none"
}, {
  "borderWidth": "0px"
}, DropDownButtonStyled, DropDownButtonStyled, DropDownContentStyled);
export var StyledHeaderStaticMobile = styled.div.withConfig({
  displayName: "HeaderStaticBlockstyled__StyledHeaderStaticMobile",
  componentId: "sc-1uduhid-3"
})(["", " ul{", " li{", " a{", " -webkit-tap-highlight-color:transparent;-webkit-touch-callout:none;-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;}}}.dropdown{&:focus-visible{outline:-webkit-focus-ring-color auto 1px;}&:hover{", "{color:#F5D1CF;transition:all .3s ease-in-out;svg{color:#FFF;}}}", "{&:focus-visible{outline:-webkit-focus-ring-color auto 1px;}}", "{position:static !important;}}"], {
  "display": "flex"
}, {
  "display": "flex",
  "listStyleType": "none",
  "flexDirection": "column"
}, {
  "paddingLeft": "1rem",
  "paddingRight": "1rem"
}, {
  "textDecoration": "none"
}, DropDownButtonStyled, DropDownButtonStyled, DropDownContentStyled);